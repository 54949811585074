





















import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  components: {
    OCompanyConfigServices: () =>
      import(
        "@/components/organisms/event/company/o-company-config-services.vue"
      ),
    OCompanyRegistrationForm: () =>
      import(
        "@/components/organisms/event/company/o-company-registration-form.vue"
      ),
    MCompanyAcceptRejectMail: () =>
      import("@/components/molecules/company/m-company-accept-reject-mail.vue"),
    MCompanyRegistrationMessage: () =>
      import(
        "@/components/molecules/company/m-company-registration-message.vue"
      ),
  },
  setup() {
    return {};
  },
});
